import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-warnings"
export default class extends Controller {
  static targets = [ "overdueWarning" ]
  connect() {
  }

  clearInputDescriptors(event) {
    this.overdueWarningTarget.classList.remove("visible")
    this.overdueWarningTarget.classList.add("invisible")
  }

}
