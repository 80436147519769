import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sortable-listing"
export default class extends Controller {
  static values = { url: String }

  connect() {
  }

  sort(event) {
    this.loadSorted(event.params["sort"], event.params["direction"])
  }

  loadSorted(column, direction) {
    if (direction == null || direction == "" ) { direction = "asc" }
    window.location.href = this.urlValue + "?sort=" + column + "&direction=" + direction
  }

}
