import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriptions"
export default class extends Controller {
  static values = { url: String }
  static targets = [ "overdueWarning" ]

  connect() {
  }

  filter_due(event) {
    event.target.form.submit()
  }

  sort(event) {
    this.loadSorted(event.params["sort"], event.params["direction"])
  }

  evaluateOverdueWarning(event) {
    if (new Date(event.target.value) < Date.now()) {
      this.overdueWarningTarget.classList.remove("invisible")
      this.overdueWarningTarget.classList.add("visible")
    } else {
      this.overdueWarningTarget.classList.remove("visible")
      this.overdueWarningTarget.classList.add("invisible")
    }
  }


  loadSorted(column, direction) {
    if (direction == null || direction == "" ) { direction = "asc" }
    window.location.href = this.urlValue + "?sort=" + column + "&direction=" + direction
  }


}
