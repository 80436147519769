import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "field"]

  connect() {
  }

  toggle() {
    const isChecked = this.checkboxTarget.checked
    this.fieldTargets.forEach(field => {
      if (isChecked) {
        field.classList.remove("hidden-field")
      } else {
        field.classList.add("hidden-field")
      }
    })
  }
}
